<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <!-- Content always starts with a v-card  -->
        <v-card class="mt-4 pa-3">
            <v-card-text>
                <v-toolbar height="72">
                    <v-tabs color="primary" left icons-and-text v-model="tab">
                        <v-tab
                            >Tenant Screening
                            <v-icon color="primary">mdi-home-city</v-icon>
                        </v-tab>
                        <v-tab
                            >Employment Screening
                            <v-icon color="primary">mdi-briefcase</v-icon>
                        </v-tab>
                    </v-tabs>
                    <v-spacer></v-spacer>
                    <div style="width: 350px !important">
                        <v-select
                            :loading="loading"
                            class="mt-6"
                            dense
                            solo
                            :items="companies"
                            item-text="name"
                            item-value="id"
                            return-object
                            placeholder="Select Company"
                        ></v-select>
                    </div>
                </v-toolbar>
                <v-tabs-items v-model="tab" class="mt-3">
                    <v-tab-item>
                        <v-row wrap>
                            <template
                                v-for="(option,
                                index) in tenantNewApplicationOptions"
                            >
                                <v-col cols="12" lg="3" md="6" :key="index">
                                    <new-application-option
                                        :option="option"
                                    ></new-application-option>
                                </v-col>
                            </template>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row wrap>
                            <template
                                v-for="(option,
                                index) in employmentNewApplicationOptions"
                            >
                                <v-col cols="12" lg="3" md="6" :key="index">
                                    <new-application-option
                                        :option="option"
                                    ></new-application-option>
                                </v-col>
                            </template>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "screening-new-application",
    components: {
        NewApplicationOption: () =>
            import("@components/screen/components/NewApplicationOption"),
    },
    data() {
        return {
            tab: null,
            page: {
                title: "New Application",
                icon: "mdi-clipboard-plus",
            },
            breadcrumbs: [
                {
                    text: "Order History",
                    disabled: false,
                    to: { name: "Order History" },
                },
                {
                    text: "New Application",
                    disabled: true,
                    to: "#",
                },
            ],
            tenantNewApplicationOptions: this.$config(
                "screen.newApplicationOptions"
            ).filter((item) => item.type === "tenant"),
            employmentNewApplicationOptions: this.$config(
                "screen.newApplicationOptions"
            ).filter((item) => item.type === "employment"),
        };
    },
    computed: {
        ...mapGetters("Companies", ["companies", "currentCompany", "loading"]),
    },
    watch: {},
    methods: {
        ...mapActions("Companies", ["getCompanies"]),
    },
    mounted() {
        this.getCompanies();
    },
    created() {},
};
</script>

<style scoped>
</style>