var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4 pa-3" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-toolbar",
                { attrs: { height: "72" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        color: "primary",
                        left: "",
                        "icons-and-text": ""
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        [
                          _vm._v("Tenant Screening "),
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("mdi-home-city")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        [
                          _vm._v("Employment Screening "),
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("mdi-briefcase")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "div",
                    { staticStyle: { width: "350px !important" } },
                    [
                      _c("v-select", {
                        staticClass: "mt-6",
                        attrs: {
                          loading: _vm.loading,
                          dense: "",
                          solo: "",
                          items: _vm.companies,
                          "item-text": "name",
                          "item-value": "id",
                          "return-object": "",
                          placeholder: "Select Company"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  staticClass: "mt-3",
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        { attrs: { wrap: "" } },
                        [
                          _vm._l(_vm.tenantNewApplicationOptions, function(
                            option,
                            index
                          ) {
                            return [
                              _c(
                                "v-col",
                                {
                                  key: index,
                                  attrs: { cols: "12", lg: "3", md: "6" }
                                },
                                [
                                  _c("new-application-option", {
                                    attrs: { option: option }
                                  })
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        { attrs: { wrap: "" } },
                        [
                          _vm._l(_vm.employmentNewApplicationOptions, function(
                            option,
                            index
                          ) {
                            return [
                              _c(
                                "v-col",
                                {
                                  key: index,
                                  attrs: { cols: "12", lg: "3", md: "6" }
                                },
                                [
                                  _c("new-application-option", {
                                    attrs: { option: option }
                                  })
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }